 
<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="6" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />

        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(aduan)="data">
          <b-row class="match-height">
            <b-col md="7">
              <h6><small class="text-primary">Sub Kegiatan</small><br>
                <small style="font-size: 15px;"><strong>1{{ data.item.kode_sub_kegiatan }}</strong>
                </small>
                <p style="text-align: justify;">{{ data.item.nama_sub_kegiatan }}</p>

              </h6>

              <b-row>
                <b-col md="12">
                  <b-button variant="outline-primary" block size="sm" v-b-modal.modal-1
                    @click="DetailKegiatan(data.item)">
                    <feather-icon icon="GridIcon" size='15' /> Detail Sub Kediatan
                  </b-button>
                </b-col>
              </b-row>

            </b-col>
            <b-col md="5">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <b-row>
                    <b-col md="12">
                      <h6><small>Capaian Kinerja Program dan Kerangka Pendanaan</small></h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <h5><small><strong>PAGU</strong></small><br>
                        <strong style="font-size: 20px;" class="text-primary"><sup><small>Rp.</small></sup>
                          {{ Rp(data.item.pagu_anggaran) }}</strong>
                      </h5>
                      <b-button variant="warning" block size="sm" class="mt-1" v-b-modal.modal-1
                        @click="DetailKegiatan(data.item)" v-if="data.item.pagu_anggaran != data.item.total_usulan">
                        <feather-icon icon="XIcon" size='15' /> Belum Sesuai
                      </b-button>
                      <b-button variant="primary" block size="sm" class="mt-1" v-b-modal.modal-1
                        @click="DetailKegiatan(data.item)" v-if="data.item.pagu_anggaran == data.item.total_usulan">
                        <feather-icon icon="GridIcon" class="mr-50" size='15' /> Sesuai
                      </b-button>
                    </b-col>
                    <b-col md="6">
                      <b-col md="12">
                        <h5><small><strong>Total RAB</strong></small><br>
                          <strong style="font-size: 15px;" class="text-primary"><sup><small>Rp.</small></sup>
                            {{ Rp(data.item.total_usulan) }}</strong>
                        </h5>
                      </b-col>
                      <b-col md="12">
                        <h5><small> Sisa Anggaran </small><br>
                          <strong style="font-size: 15px;" class="text-danger"><sup><small>Rp.</small></sup>
                            {{ Rp(data.item.sisa_anggaran) }}</strong>
                        </h5>
                      </b-col>
                    </b-col>
                  </b-row>
                </div>
              </b-alert>

            </b-col>
            <b-col md="2">

            </b-col>
          </b-row>
        </template>


      </b-table>
    </b-col>
    <b-col cols="6">
      Total Ajuan {{ items.length }}
    </b-col>
    <b-col cols="6">
      <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import Base from '@/config/Mixins_base';
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BAlert
  },
  props: {
    rsData: {}
  },
  mixins: [Base],
  emits: ['DetailKegiatan'],
  data() {
    return {
      perPage: 5,
      pageOptions: [15, 5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'aduan',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],

    }
  },
  computed: {
    items: {
      get: function () {
        return this.rsData;
      },
      set: function (newValue) {
        return this.rsData = newValue;
      }
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
  },
  methods: {
    DetailKegiatan(val) {
      this.$emit("DetailKegiatan", val)
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
 