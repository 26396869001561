<template>
  <div>

    <b-modal id="modal-1" title="Detaik kegiatan" ok-disabled size="xl" hide-footer no-close-on-esc>
      <b-alert variant="warning" show>
        <div class="alert-body text-dark">
          <b-row>
            <b-col md="6">
              <b-row>
                <b-col md="3"><strong>{{ detail.kode_program }}</strong></b-col>
                <b-col md="9"> {{ detail.nama_program }} </b-col>
                <b-col md="3"><strong>{{ detail.kode_kegiatan }}</strong></b-col>
                <b-col md="9"> {{ detail.nama_kegiatan }} <br></b-col>
                <b-col md="3"><strong>{{ detail.kode_sub_kegiatan }}</strong></b-col>
                <b-col md="9"> {{ detail.nama_sub_kegiatan }}

                  <!-- <br><small><b>Indikatro Sub Kegiatan :
                    </b>
                    {{ detail.indikatror }} ({{ detail.satuan }})</small>
                  <br><small><b>Indikatro Sub Kegiatan : </b>{{ detail.indikatror }}
                  </small>
                  <br><small><b>Kinerja Sub Kegiatan : </b>{{ detail.kinerja }}
                  </small> -->

                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="6">
                  <h5><small><strong>PAGU</strong></small><br>
                    <strong style="font-size: 30px;" class="text-primary"><sup><small>Rp.</small></sup>
                      {{ Rp(detail.pagu_anggaran) }}</strong>
                  </h5>
                  <b-button variant="warning" block size="sm" class="mt-1">
                    <feather-icon icon="XIcon" size='15' /> Status Sesuai
                  </b-button>

                  <b-button variant="outline-info" block size="sm" class="mt-1">
                    Status Sub Kegiatan
                    {{ detail.status }}
                  </b-button>
                </b-col>
                <b-col md="6">
                  <b-col md="12">
                    <h5><small><strong>Jumlah Pekerjaan</strong></small><br>
                      <strong style="font-size: 25px;" class="text-primary"><sup><small></small></sup>
                        {{ detail.jm }}</strong>
                    </h5>
                  </b-col>
                  <b-col md="12">
                    <h5><small><strong>Total Pengajuan Anggaran </strong></small><br>
                      <strong style="font-size: 25px;" class="text-primary"><sup><small>Rp.</small></sup>
                        {{ Rp(detail.total_usulan) }}</strong>
                    </h5>
                  </b-col>
                  <b-col md="12">
                    <h5><small> Sisa Anggaran </small><br>
                      <strong style="font-size: 25px;" class="text-danger"><sup><small>Rp.</small></sup>
                        {{ Rp(detail.sisa_anggaran) }}</strong>
                    </h5>
                  </b-col>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-alert>
      <hr>
      <b-row>
        <b-col md="9">
          <h5 class="text-primary"> <b-avatar rounded="" variant="primary" size="25"><feather-icon icon="GridIcon"
                size='15' /></b-avatar>
            Daftar Ajuan Pekerjaan
            <div v-if="detail.total_usulan >= detail.pagu_anggaran">
              <small><i> ****Pengajuan anggaran sudah memenuhi batas maksimal Pagu Anggaran</i></small>
            </div>
          </h5>

        </b-col>
        <b-col md="3" v-if="detail.status == 'Penyusunan Anggaran'">
          <div>
            <b-button variant="success" block class="mb-1" size="sm" v-b-modal.modal-add @click="default_kodisi_jalan()">
              <feather-icon icon="PlusCircleIcon" size='15' /> Tambah Ajuan Pekerjaan
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row v-for="rs in DetailSub" v-bind:key="rs.id_usulan_kegiatan">
        <b-col md="12">
          <b-alert variant="info" show>
            <div class="alert-body text-dark">
              <b-row>
                <b-col md="9">
                  <b-row>
                    <b-col md="5">
                      <h5 class="text-primary"> <span class="fz15 text-primary"> {{ rs.balai_bidang }}</span></h5>
                      <h5><small><b>Nama Pekerjaan</b></small><br><span class="fz15 text-primary">{{ rs.nama_pekerjaan
                      }}</span>
                      </h5>
                      <h6><small><b>Rincian Pekerjaan</b></small><br><span class="fz15 text-primary">
                          {{
                            rs.satuan_target_pekerjaan
                          }}</span>
                      </h6>
                    </b-col>
                    <b-col md="2">
                      <h5><small><b>Jenis Pekerjaan</b></small><br><span class="fz15 text-primary">{{
                        rs.id_pekerjaan.nama_pekerjaan }}</span>
                      </h5>
                      <h5 class="text-primary"><small>Pagu Anggaran</small><br><strong class="fz30"><sup>Rp.</sup>{{
                        Rp(rs.pagu_usulan)
                      }},-</strong></h5>
                    </b-col>
                    <b-col md="5">

                      <h5 v-if="rs.id_ruas_jalan != ''"><small><strong>Ruas Jalan</strong></small><br><span
                          class="fz15 text-primary">
                          <!-- {{ rs.id_ruas_jalan.no_ruas_jalan }} - -->
                          {{ rs.id_ruas_jalan.nama_ruas_jalan }} , Wilayah {{ rs.id_ruas_jalan.nm_wil
                          }}</span><br><small>Detail Lokasi {{ rs.detail_lokasi }}</small>
                      </h5>
                      <h6><small><b>Target Pekerjaan</b></small><br><span class="fz15 text-primary">
                          {{ rs.target_pekerjaan }} {{ rs.satuan }}</span>

                      </h6>

                    </b-col>

                  </b-row>
                </b-col>
                <b-col md="3">
                  <b-badge variant="info" block size="sm" v-show="rs.note != '' || rs.koreksi_anggaran != '' &&
                    rs.validasi == '1'" class="btn-block" v-b-modal.modal-4
                    @click="LoadNote(rs.note, rs.koreksi_anggaran)">
                    <feather-icon icon="CheckIcon" size='15' />Pengajuan Tervalidasi
                  </b-badge>
                  <b-row class="mb-1" v-if="rs.validasi == '0'">
                    <b-col col=6>
                      <b-button variant="warning" block size="sm">
                        <feather-icon icon="EditIcon" class="mr-50" size='15' /> Edit
                      </b-button>
                    </b-col>
                    <b-col col=6>
                      <b-button variant="danger" block size="sm" @click="hapusSubSubKegiatan(rs.id_usulan_kegiatan)">
                        <feather-icon icon="TrashIcon" class="mr-50" size='15' /> Hapus
                      </b-button>
                    </b-col>
                  </b-row>
                  <div v-if="rs.dokumen != ''">
                    <h5>File Pendukung Kegiatan</h5>
                    <div>
                      <b-row>
                        <b-col md="6" v-for="doc in rs.dokumen" v-bind:key="doc">

                          <div v-if="doc.file_dokumen == null">
                            <a target="_blank">
                              <h6><b-avatar rounded="" variant="danger" size="20">
                                  <feather-icon icon="XIcon" size='15' />
                                </b-avatar>
                                <span class="text-danger"> <small><sub> Belum
                                      Upload {{ doc.jenis_dokumen }}</sub></small></span>
                              </h6>
                            </a>
                          </div>

                          <div v-if="doc.file_dokumen != null">
                            <a :href="doc.file_dokumen" target="_blank">
                              <h6><b-avatar rounded="" variant="primary" size="20"><feather-icon icon="DownloadIcon"
                                    size='10' /></b-avatar>
                                <span class="text-primary"> {{ doc.jenis_dokumen }}</span>
                              </h6>
                            </a>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>

                  <div v-if="rs.validasi == '0'">
                    <b-badge variant="light-warning" block size="sm" v-show="rs.note != '' || rs.koreksi_anggaran != '' &&
                      rs.validasi == '0'" class="btn-block mb-1" v-b-modal.modal-4
                      @click="LoadNote(rs.note, rs.koreksi_anggaran)">
                      <feather-icon icon="BellIcon" size='15' /> Catatan Validator Pengajuan Anggaran
                    </b-badge>
                  </div>

                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BAvatar, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BRow, BCol, BAvatar, BBadge,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ['hapusSubSubKegiatan', 'default_kodisi_jalan', 'LoadNote'],
  methods: {
    LoadNote(val1, val2) {
      this.$emit('LoadNote', val1, val2)
    },
    default_kodisi_jalan() {
      this.$emit('default_kodisi_jalan')
    },
    hapusSubSubKegiatan(val) {
      if (confirm("apakah anda akan hapus data ini") == true) {
        this.$emit('hapusSubSubKegiatan', val);
      }
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    close() {
      this.$root.$emit('bv::hide::modal-1', 'AddLevel')
    }
  },
  props: {
    rsDetail: {},
    rsDetailSub: {}
  },
  computed: {
    detail: {
      get: function () {
        return this.rsDetail;
      },
      set: function (newValue) {
        return this.rsDetail = newValue;
      }
    },
    DetailSub: {
      get: function () {
        return this.rsDetailSub;
      },
      set: function (newValue) {
        return this.rsDetailSub = newValue;
      }
    },

  },
}
</script>
<style >
.fz15 {
  font-size: 14px
}

.fz30 {
  font-size: 20px
}

.btn-block {
  display: block;
  width: 100%;
}
</style>